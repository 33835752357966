.bottom_footer {
    color: #7f88a6;
}

.first_footer_column {
    visibility: visible;
    animation-delay: 0.2s;
    animation-name: fadeInLeft;
}

.second_footer_column {
    visibility: visible;
    animation-delay: 0.4s;
    animation-name: fadeInLeft;
}

.third_footer_column {
    visibility: visible;
    animation-delay: 0.6s;
    animation-name: fadeInLeft;
}

.fourth_footer_column {
    visibility: visible;
    animation-delay: 0.8s;
    animation-name: fadeInLeft;
}

.error_footer_column {
    display: none;
}

.ciz {
    font-size: 16px;
}

.footer_main {
    position: relative;
    overflow-x: hidden;
    background-color: #fbfbfd;
    z-index: -1;
}

.footer_bg {
    bottom: 0;
    background: url('../Assets/images/footbg.png') scroll center 0;
    position: relative;
    width: 100vw;
    height: 266px;
}

.footer_bg .footer_bg_bus {
    background: url('../Assets/images/bus.gif') no-repeat center center;
    width: 330px;
    height: 105px;
    background-size: contain;
    position: absolute;
    bottom: 0;
    left: 30%;
    -webkit-animation: myfirst 22s linear infinite;
    animation: myfirst 22s linear infinite;
}

.footer_bg .footer_bg_bike {
    background: url('../Assets/images/ride.gif') no-repeat center center;
    width: 88px;
    height: 100px;
    background-size: contain;
    bottom: 0;
    left: 38%;
    position: absolute;
    -webkit-animation: myfirst 30s linear infinite;
    animation: myfirst 30s linear infinite;
}

.footer_verified_info {
    background-color: #fafafa;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.footer_main_top .f_widget.about-widget .f_list li a {
    color: #6a7695;
}

.footer_main_top .company_widget p {
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    color: #6a7695;
    margin-bottom: 20px;
}

.footer_main_top .company_widget .f_subscribe_two .btn_get {
    border-width: 1px;
    margin-top: 20px;
}

.btn_get_two {
    -webkit-box-shadow: none;
    box-shadow: none;
    background: #28a745;
    border-color: #28a745;
    color: #fff;
}

.btn_get_two:hover {
    background: transparent;
    color: #28a745;
}

.footer_main_top .f-title {
    margin-bottom: 10px;
    color: #131921;
}

.btn_get:hover {
    background: transparent;
    color: #28a745;
    border-color: #28a745;
}

a:hover,
a:focus,
.btn:hover,
.btn:focus,
button:hover,
button:focus {
    text-decoration: none;
    outline: none;
}

.footer_main_top .f_widget.about-widget .f_list li a:hover {
    color: #28a745;
}

.footer_main_top .f_widget.about-widget .f_list li {
    margin-bottom: 11px;
}

.f_widget.about-widget .f_list li:last-child {
    margin-bottom: 0px;
}

.f_widget.about-widget .f_list li {
    margin-bottom: 15px;
}

.f_widget.about-widget .f_list {
    margin-bottom: 0px;
}

.f_600 {
    font-weight: 600;
}

.f_size_18 {
    font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #4b505e;
}

@-moz-keyframes myfirst {
    0% {
        left: -25%;
    }

    100% {
        left: 100%;
    }
}

@-webkit-keyframes myfirst {
    0% {
        left: -25%;
    }

    100% {
        left: 100%;
    }
}

@keyframes myfirst {
    0% {
        left: -25%;
    }

    100% {
        left: 100%;
    }
}

@media screen and (min-width: 800px) {
    .copyright {
        text-align: left;
    }

    .ciz {
        text-align: right;
    }
}

@media (max-width: 768px) {
    .footer_bg {
        height: 150px;
        background-size: cover;
    }

    .footer_bg .footer_bg_bus {
        width: 220px;
        height: 70px;
        left: 20%;
    }

    .footer_bg .footer_bg_bike {
        width: 60px;
        height: 50px;
        left: 28%;
    }
}