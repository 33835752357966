.checkout__total {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 27vw;
  height: auto;
  padding: 20px;
  background-color: #f3f3f3;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.checkout__total_col {
  margin-top: -1.5em;
}

.checkout__total_row {
  font-size: 14px;
}

.checkout__total_row_col {
  margin-top: -2.2em;
}

.pstackButton {
  background-color: #01d168;
  border: 1px solid;
  border-color: linear-gradient(to right, #01a853, #01d168, #00b05b) 1;
  color: white;
  margin: -21px;
  padding: 12px 40px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.pstackButton:hover {
  background-color: #028644;
  cursor: pointer;
}

.thankspan {
    margin-top: 1.5em;
  }

.check-check {
  display: flex;
}

.chch {
  justify-content: center;
  align-items: center;
  text-align:center;
}

@media (min-width: 768px) {
  .checkout__total {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .pstackButton {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
}

@media (max-width: 767px) {
  .checkout__total {
    margin-bottom: 4em;
    height: auto;
    padding: 20px;
    background-color: #f3f3f3;
    border: 1px solid #dddddd;
    min-width: 100vw;
    margin-top: 1em;
  }

  .pstackButton {
    margin: -22px;
    padding: 16px 40px;
    font-size: 16px;
  }

  .thankspan {
    margin-top: -0.5em;
  }
}

.ptag_checkout {
  margin-top: -0.6em;
}

.ttotal {
  margin-bottom: 0px;
  cursor: pointer;
}

.ttotal:hover {
  color: white;
}

.ttotal-container {
  color: white;
}

.ttotal-container:hover {
  color: white;
}