.signup {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6%;
}

.signup__logo {
    margin-top: 40px;
    margin-bottom: 35px;
    object-fit: contain;
    width: 150px;
    margin-right: auto;
    margin-left: auto;
}

.signup_container {
    width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 20px;
    margin: 0 auto;
}

.signup_container>h2 {
    font-weight: 500;
    font-size: medium;
    margin-bottom: 25px;
}

.signup_container>form>div>input {
    height: 30px;
    margin-bottom: 20px;
    background-color: white;
    width: 100%;
}

.signup_P {
    margin-top: 15px;
    font-size: 10.5px;
    width: 300px;
}

.signup_createButton {
    background: #01D168;
    border-radius: 2px;
    width: 100%;
    height: 35px;
    border: 1px solid;
    margin-top: 10px;
    border-color: linear-gradient(to right, #01A853, #01D168, #00B05B) 1;
    color: white;
}

.signup_createButton:hover {
    background: #028644;
}

.signup_signInButton>button:hover {
    background-color: #01a853;
    color: black;
    cursor: pointer;
}

.signup_loginHere_Button {
    border-radius: 2px;
    height: 30px;
    border: 1px solid;
    border-color: darkgray;
    color: black;
    padding: 0 10px;
}

.signup_country {
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: darkgray;
    color: black;
}

.signup_loginHere {
    font-size: 15px;
}

.signup_loginHere_Button:hover {
    background-color: #028644;
    color: white;
}

.error {
    color: red;
    font-size: 13px;
}

.form_auth input[type="text"],
input[type="password"] {
    padding-left: 10px;
    padding-right: 10px;
}