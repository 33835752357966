.shop-category {
  padding: 20px;
}

.shop-category h2 {
  margin-bottom: 20px;
}

.filter-sort-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.category_product-card {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.pagination {
  margin-top: 20px;
  text-align: center;
}

.topnav-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Media queries for responsive adjustments */
@media (max-width: 1024px) {
}

@media (max-width: 768px) {
  .below_navbar a {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .topnav-center {
    left: 20%;
    transform: translate(-15%, -50%);
    text-wrap: nowrap;
  }
}

.topnav-center .active {
  font-weight: bold;
  color: #028644;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 2.5em;
}

.page-button {
  margin: 0 5px;
  padding: 6px 15px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}

.page-button.active {
  background-color: #028644;
  color: #fff;
}

.page-button:hover:not(.active) {
  background-color: #ddd;
}