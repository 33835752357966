.order-list {
  padding: 20px;
  max-width: 1030px;
  margin-bottom: 3em;
  margin-top: 1em;
}

.order-list h2 {
  margin-bottom: 20px;
}

.order-list__items {
  list-style: none;
  padding: 0;
}

.order-list__item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.order-list__item a {
  text-decoration: none;
  color: #007bff;
}

.order-list__item a:hover {
  text-decoration: underline;
}