.form-window {
  width: 100%;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  text-align: center;
}

.stripe {
  position:relative;
  top: -45px;
  width: 100%;
  height: 254px;
  background-color: #028644;
  transform: skewY(-12deg);
}

.eftran {
  position: absolute;
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: white;
  z-index: 998;
  opacity: 0.33;
}

.loading-icon {
  position: absolute;
  font-size: 80px;
  z-index: 1000;
  margin-top: -25%;
  right: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #01d26a;
}

.ava {
  position: absolute;
  width: 100%; 
  height: 100%; 
  text-align: center;
  animation: fadeIn 0.8s ease-in-out forwards;
}

.avatarr {
  position: relative;
  top: -53%;
  filter: invert(100%);
  width: 65px;
  height: 65px;
}

.toptext {
  position: relative;
  width: 100%; 
  top: -41%; 
  color: white; 
  font-size: 22px; 
  font-weight: 600;
  padding: 24px;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards 0.5s;
}

.emailInput {
  width: 80%;
  height: 11%;
  margin-top: -55%;
  margin-left: 11%;
  background-color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  outline: none;
  padding: 7px;
  border-radius: 12px;
  border: 2px solid #028644;
  opacity: 0;
  animation: fadeIn 1.2s ease-in-out forwards 0.5s;
}

.emailInput input {
  width: 76%;
  height: 100%;
  margin-top: -55%;
  padding-right: 3em;
  margin: 0;
  padding: 2px;
  border: none;
  outline: none;
  text-align: center;
  font-size: 16px;
  box-sizing: border-box;
  transition: border-color 0.3s ease-in-out;
  z-index: 997;
}

.emailInput input::placeholder {
  color: #aaa;
  text-align: center;
}

.bottomText {
  position: absolute; 
  padding: 24px;
  width: 65%; 
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  top: 60%; 
  margin-top: -8%;
  margin-left: 18%;
  color: #028644; 
  font-size: 20px; 
  font-weight: 600;
  opacity: 0;
  animation: fadeIn 2s ease-in-out forwards 0.5s; 
}

.lasttext {
  margin-top: 36%;
  padding: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #028644; 
  font-size: 12px; 
  font-weight: 600;
  opacity: 0;
  animation: fadeIn 3s ease-in-out forwards 0.5s; 
}

.btnsendd {
  color: #028644;
  border: none;
  background-color: transparent;
  margin-right: -5%;
  padding-left: 4%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}