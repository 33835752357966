.form-window-mobile {
    width: 100%;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    text-align: center;
    height: 100vh;
    position: fixed;
  }
  
  .stripe-mobile {
    position:relative;
    top: -59px;
    width: 100%;
    height: 400px;
    background-color: #028644;
    transform: skewY(-12deg);
  }
  
  .eftran-mobile {
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
    background-color: white;
    z-index: 998;
    opacity: 0.33;
  }
  
  .loading-icon-mobile {
    position: absolute;
    font-size: 80px;
    z-index: 1000;
    margin-top: -25%;
    right: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #01d26a;
  }
  
  .ava-mobile {
    position: absolute;
    width: 100%; 
    height: 100%; 
    text-align: center;
    animation: fadeIn 0.8s ease-in-out forwards;
  }
  
  .avatarr-mobile {
    position: relative;
    top: -48%;
    filter: invert(100%);
    width: 65px;
    height: 65px;
  }
  
  .toptext-mobile {
    position: relative;
    width: 100%; 
    top: -36%; 
    color: white; 
    font-size: 24px; 
    font-weight: 600;
    padding: 24px;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards 0.5s;
  }

  .btnchat {
    margin-top: -54%;
    margin-left: -1%;
  }
  
  .btn-login {
    width: 36%;
    font-size: large;
    height: 7%;
    background-color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    outline: none;
    padding: 7px;
    border-radius: 12px;
    border: 2px solid #028644;
    opacity: 0;
    animation: fadeIn 1.2s ease-in-out forwards 0.5s;
    cursor: pointer;
    color: #028644;
    font-weight: 600;
  }

  .btnsendd-mobile {
    padding-left: 12% !important;
    padding-right: -6% !important;
  }
  
  /* .emailInput-mobile input {
    width: 76%;
    height: 100%;
    margin-top: -55%;
    padding-right: 3em;
    margin: 0;
    padding: 2px;
    border: none;
    outline: none;
    text-align: center;
    font-size: 18px;
    box-sizing: border-box;
    transition: border-color 0.3s ease-in-out;
    z-index: 997;
  }
  
  .emailInput-mobile input::placeholder {
    color: #aaa;
    text-align: center;
  } */
  
  .bottomText-mobile {
    position: absolute; 
    padding: 24px;
    width: 65%; 
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    top: 60%; 
    margin-top: -8%;
    margin-left: 18%;
    color: #028644; 
    font-size: 23px; 
    font-weight: 600;
    opacity: 0;
    animation: fadeIn 2s ease-in-out forwards 0.5s; 
  }
  
  .lasttext-mobile {
    margin-top: 40%;
    padding: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #028644; 
    font-size: 15px; 
    font-weight: 600;
    opacity: 0;
    animation: fadeIn 3s ease-in-out forwards 0.5s; 
  }
  
  .btnsendd-mobile {
    color: #028644;
    border: none;
    background-color: transparent;
    margin-right: -5%;
    padding-left: 4%;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }