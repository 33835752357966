.all-products {
  padding: 20px;
  flex-grow: 1;
}

.all-products h4 {
  margin-bottom: 0;
}

.pagination {
  margin-top: 20px;
  text-align: center;
}

.all_product-card {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  width: 100%;
}

@media (max-width: 767px) {
  .all_product-card {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .all_product-card {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 992px) {
  .all_product-card {
    grid-template-columns: repeat(4, 1fr);
  }
}

.main-container {
  display: flex;
}

.all-product_header-bar {
  padding: 10px 0;
}

.all-product_header-bar p {
  margin: 0;
}

.below_navbar {
  position: relative;
  overflow: hidden;
  background-color: #e7e6e6;
  height: 42px;
}

.below_navbar a {
  color: #131921;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 16px;
}

.below_navbar a:hover {
  cursor: pointer;
}

.topnav-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .below_navbar a {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .topnav-centered {
    left: 30%;
    transform: translate(-20%, -50%);
  }
}

.topnav-centered .active {
  font-weight: bold;
  color: #028644;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 2.5em;
}

.page-button {
  margin: 0 5px;
  padding: 6px 15px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
}

.page-button.active {
  background-color: #028644;
  color: #fff;
}

.page-button:hover:not(.active) {
  background-color: #ddd;
}