.thankYou {
  padding-top: 10px;
  margin: 0 auto;
}

.thankYou__icon {
  color: #01d26a;
  font-size: 40px;
}

.header_thankyou {
  padding: 20px 0px 0px 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 1em;
}

.thankYou__header {
  display: flex;
  margin-top: -2em;
  margin-bottom: 1em;
  color: gray;
  font-size: 15px;
}

.thankYou__details {
  text-align: left;
  margin-top: 20px;
}

.thankYou__section {
  margin-bottom: 20px;
}

.thankYou__section h4 {
  margin-bottom: 5px;
  color: #555;
}

.thankYou__section p {
  margin-top: -5px;
  color: #555;
}

.thankYou__section h6 {
  margin-bottom: 1em;
  color: #555;
}

.thankYou__container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.thankYou__header p {
  margin-left: 0.6em;
}

.thank-you-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;
}

.thank-you-container h1 {
  font-size: 2.5em;
  color: #343a40;
  margin-bottom: 20px;
}

.thank-you-container p {
  font-size: 1.2em;
  color: #6c757d;
  margin-bottom: 10px;
}

.thank-you-container a {
  color: #028644;
  text-decoration: none;
}

.thank-you-container a:hover {
  text-decoration: underline;
}

.thank-you-container button {
  font-size: 1em;
  color: #fff;
  background-color: #01d26a;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.thank-you-container button:hover {
  background-color: #028644;
}

.thankYou__details span {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .thankYou__container {
    width: 99.5svw;
    border: 1px solid #ddd;
    border-radius: 0px;
    margin-left: -1em;
  }

  .header_thankyou {
    width: 99.5svw;
    border: 1px solid #ddd;
    border-radius: 0px;
    margin-left: -1em;
  }
}
@media (min-width: 768px) {
  .thankYou {
    padding-top: 1em;
    margin-left: 6em;
    width: 100%;
  }
}

.accInfo p {
  color: #555;
}
