.home_Container {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.rot {
  rotate: -90deg;
  margin-top: 6px;
  margin-left: -4px;
}

.header_image {
  cursor: pointer;
}

.home_image2 {
  cursor: pointer;
}

.header_image-img {
  width: 100%;
  height: 34vw;
  border-radius: 0 20px;
}

.header_image-img2 {
  width: 100%;
  height: auto;
}

.roundedcat-home {
  margin-top: 0.6em;
}

.home_container_row {
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 1rem;
}

.home_px-4 {
  padding-left: 0.3rem;
  padding-right: 1rem;
}

.home_px-lg-5 {
  padding-right: 3rem;
}

.home_mt-5 {
  margin-top: 1.5rem;
}

.home_row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: 1.5rem;
}

.home_col {
  position: relative;
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.home_mb-5 {
  margin-bottom: 3rem;
}

.home_h-100 {
  height: 100%;
}

.home_card-body {
  flex: 1 1 auto;
  padding: 1.5rem;
  font-size: 1.3rem;
  word-break: break-all;
  text-overflow: ellipsis;
}

.home_p-4 {
  padding: 1.5rem;
}

.home_text-center {
  text-align: center !important;
}

.home_fw-bolder {
  font-weight: normal;
}

.home_pt-0 {
  padding-top: 0 !important;
}

.home_border-top-0 {
  border-top: 0 !important;
}

.home_bg-transparent {
  background-color: transparent !important;
}

.home_btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.home_btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.home_mt-auto {
  margin-top: auto !important;
}

.home_badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.home_bg-dark {
  background-color: #343a40 !important;
}

.home_text-white {
  color: #fff !important;
}

.position-absolute {
  position: absolute !important;
}

.home_text-muted {
  color: #6c757d !important;
}

.home_text-decoration-line-through {
  text-decoration-line: line-through !important;
}

.home_d-flex {
  display: flex !important;
}

.home_small {
  font-size: 0.875rem !important;
}

.home_text-warning {
  color: #ffc107 !important;
}

.home_text-decoration-line-through {
  text-decoration-line: line-through;
}

.home_text-muted {
  color: #6c757d;
}

.home_price {
  padding: 1.5rem;
  border-top: 0;
  padding-top: 0 !important;
  border-top: 0 !important;
  font-weight: 500;
}

.home_cart_title {
  padding-top: -0.25rem;
}

.home_view-all_button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: -1.6rem;
}

.home_view-all_button-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  text-align: center;
}

.home_view-all_a {
  color: white;
  text-decoration: none;
}

.tag-cloud {
  margin-top: 10px;
}

.tag-cloud a {
  display: inline-block;
  margin: 5px;
  padding: 5px 2px;
  font-size: 14px;
  background-color: #f2f2f2;
  color: #333;
  text-decoration: none;
  border-radius: 3px;
}

.tag-cloud a:hover {
  background-color: #333;
  color: #f2f2f2;
}

.sc_container-row {
  margin: 0 auto;
  height: auto;
  margin-top: 1.95em;
  margin-bottom: -0.3em;
}

.scrolling-row {
  display: flex;
  overflow-x: auto;
  flex-basis: 0;
  margin: 0 auto;
  align-items: center;
}

.scrolling-row::-webkit-scrollbar {
  display: none;
}

.category-cell {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto;
  max-width: 128px;
  min-width: 160px;
  height: 208px;
  width: 208px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  top: -1rem;
}

.home_product-card {
  display: grid;
  grid-template-columns: repeat(6, minmax(2px, 1fr));
  gap: 10px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  text-align: center;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: auto;
  justify-content: space-between;
}

.home_product-card::-webkit-scrollbar {
  display: none;
}

.scroll_hover-overlay {
  position: absolute;
  top: 1.5em;
  left: 0;
  width: 100%;
  height: 77%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.scroll_hover-overlay:hover {
  opacity: 1;
}

/* .scrolling-row::-webkit-scrollbar {
    display: none;
} */

.scroll_shop-now-button {
  padding: 8px 16px;
  font-size: 15px;
  font-weight: bold;
  background-color: transparent;
  color: white;
  border: solid;
  cursor: pointer;
  margin-top: 1em;
}

.scroll_shop-now-button:hover {
  background-color: #ffffff;
  color: #000000;
}

.scroll1 {
  background: url(../../Components/Assets/images/scroll/square/square1.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.scroll2 {
  background: url(../../Components/Assets/images/scroll/square/square2.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.scroll3 {
  background: url(../../Components/Assets/images/scroll/square/square3.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.scroll4 {
  background: url(../../Components/Assets/images/scroll/square/square4.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.scroll5 {
  background: url(../../Components/Assets/images/scroll/square/square5.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.scroll6 {
  background: url(../../Components/Assets/images/scroll/square/square6.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.scroll7 {
  background: url(../../Components/Assets/images/scroll/square/square7.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.scroll8 {
  background: url(../../Components/Assets/images/scroll/square/square8.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.scroll9 {
  background: url(../../Components/Assets/images/scroll/square/square9.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.scroll10 {
  background: url(../../Components/Assets/images/scroll/square/square10.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.scroll-left-button {
  position: absolute;
  left: 3.1%;
  transform: translateY(-50%);
  color: black;
  border: none;
  cursor: pointer;
  background-color: transparent;
  z-index: 2;
  margin-top: 2em;
}

.scroll-right-button {
  position: absolute;
  right: 3%;
  margin-top: 1.9em;
  transform: translateY(-50%);
  color: black;
  border: none;
  cursor: pointer;
  background-color: transparent;
  z-index: 2;
}

@media (max-width: 767px) {
  .ffp-home {
    margin-top: -1em;
  }

  .scroll_shop-now-button {
    padding: 5px 10px;
    font-size: 12px;
    font-weight: bold;
    background-color: transparent;
    color: white;
    border: solid;
    margin-top: 1em;
  }

  .scroll_hover-overlay {
    position: absolute;
    top: 1.5em;
    left: 0;
    width: 100%;
    height: 77%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}

.main_view-all {
  margin-top: -2rem;
}

@media (max-width: 1399px) {
  .hide-on-medium-screens {
    top: 61rem;
  }
}

@media (max-width: 1259px) {
  .hide-on-medium-screens {
    display: none !important;
  }
}

@media (min-width: 1260px) {
  .scrolling-row::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 900px) {
  .scrolling-row::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 767px) {
  .home_product-card {
    display: flex;
    width: 100%;
    gap: 7px;
    overflow-x: scroll;
    overflow-y: hidden;
    text-align: center;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: auto;
    overflow-x: scroll;
    flex: 1;
    justify-content: space-between;
  }
}

@media (min-width: 1259px) {
  .header_image-img {
    height: auto;
  }
}

.mb5 {
  margin-bottom: 5.2em;
}
