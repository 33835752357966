body {
  padding-bottom: 2em;
}

.mobile-fixed-bottom {
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px 0;
  border-top: 1px solid #ddd;
  width: 100%;
  z-index: 998;
  padding-bottom: 1em;
  filter: none;
  pointer-events: auto;
}

.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.icon-container span {
  font-size: 9px;
  color: #b0b0b0;
  margin-top: 1px;
  font-family: "Arial";
}

.icon-container span.active {
  color: black;
}

.cart-icon {
  width: 50px;
  border-radius: 50%;
}

.icon-container:nth-child(3) span {
  display: none;
}



.blur-wrapper {
  position: absolubte;
}

.blur-background {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
}

