.rounded_category {
  display: flex;
  flex-direction: column;
  gap: 1.25em;
}

.rounded_category-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.875em;
  text-align: center;
  margin: 20px 0px;
  overflow-x: scroll;
}

.rounded_category-list::-webkit-scrollbar {
  display: none;
}

.rounded_category-list-item img {
  min-width: 80px;
  width: 7.5vw;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.2s;
}

.rounded_category-list-item p {
  margin-top: 8%;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
}
