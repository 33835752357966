* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mobile_header {
  background-color: #01d26a;
  margin-bottom: 0px;
}

.search-mobile {
  background-color: #01d26a;
  display: flex;
  justify-content: center;
  height: 12vh;
  margin: 0;
  border: none;
  transition: height 0.3s ease;
}

.search-mobile.scrolled {
  height: 10vh;
  position: fixed;
  top: 0;
  z-index: 1020;
  width: 100%;
}

@media (orientation: landscape) {
  .search-mobile.scrolled {
    height: 17vh;
    position: fixed;
    top: 0;
    z-index: 1020;
    width: 100%;
  }
  .search-mobile {
    height: 20vh;
  }
}

.mobile_header__search {
  display: flex;
  flex: 1;
  align-items: center;
  position: sticky;
  position: -webkit-sticky;
}

.mobile_header__searchInput {
  height: 44px;
  padding: 7px;
  padding-right: 40px;
  border: none;
  outline: none;
  width: 100%;
}

.mobile_header__searchIcon {
  position: absolute;
  padding: 2px;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
}

.mobile_header_cart {
  float: right;
  margin-top: -0.5rem;
}

.mobile_icon {
  color: black;
  margin-left: -7px;
  margin-right: auto;
}

.mobile_login_text {
  color: black;
  font-size: 13.5px;
}

.mobile_icon_person {
  font-size: 1rem;
}

.mobile_arrow {
  margin-left: -3px;
}

.mobile_header_logo {
  width: 170px;
  margin: auto;
  height: auto;
  max-width: 100%;
  max-height: 60px;
}

.mobile_header_log {
  width: 170px;
  margin: auto;
  height: auto;
  max-width: 100%;
  max-height: 60px;
  padding-bottom: 9%;
}

.mobile_login_icon {
  margin-left: auto;
  margin-right: 0.7rem;
  margin-top: -0.5rem;
}

.mobile_navbar-brand {
  display: grid;
  margin: 0 auto;
  padding-top: 1.4125rem;
  padding-left: 0.7125rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.mobile_navbar-brand:hover,
.mobile_navbar-brand:focus {
  text-decoration: none;
}

.mobile_cartCount {
  font-weight: 800;
}

.mobile_mobile_login a {
  color: black;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.mobile_hr {
  margin-top: 4rem;
  margin-bottom: -5rem;
}

.mobile_badge {
  display: inline-block;
  padding: 0.25em 0.45em;
  font-size: 80%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* nav overlay below*/

.open .navbar-toggler-icon {
  display: none;
}

.nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.overflow-hidden {
  overflow: hidden;
}

.open .nav-overlay {
  opacity: 1;
  pointer-events: auto;
}

.nav-content {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  transform: translateX(0);
  transition: transform 0.5s ease;
  overflow-y: auto;
  height: 100vh;
}

.open .nav-content {
  transform: translateX(100%);
}

.mobile__navbar-nav {
  padding: 0 20px;
  list-style: none;
}

.mobile_header.open {
  overflow: hidden;
}

.mobile_header.open .nav-overlay {
  opacity: 1;
  pointer-events: auto;
}

.mobile_header.open .nav-content {
  transform: translateX(0);
  right: auto;
}

.close-button {
  position: absolute;
  top: 8px;
  right: 13px;
  padding: 5px;
  background-color: transparent;
  border: none;
  color: #fff;
}

.mobile_header.open .nav-content .close-button {
  color: #000;
}

.dot {
  height: 30px;
  width: 30px;
  top: 27px;
  right: 15px;
  padding-top: -2rem;
  border-radius: 50%;
  display: inline-block;
}

body.menu-open {
  overflow: hidden;
}

.toggle_logo {
  display: grid;
  margin-top: 1rem;
}

.toggle_browse_all {
  font-size: smaller;
  margin-top: 3px;
  font-weight: 600;
}

.toggle_list {
  list-style: none;
  margin-left: -2rem;
  margin-right: -1.2rem;
}

.mobile__browse {
  font-size: x-small;
}

.toggle_menu_main {
  margin-top: 1rem;
}

.mobile_menu_style {
  margin-top: -1rem;
}

.mobile_menu_style_text {
  font-size: 19px;
}

.mobile_menu_style_sub {
  font-size: 15.5px;
}

.mobile_menu_style_pad {
  padding-bottom: 0.75rem;
}

.mobile_menu_style_text_a {
  color: black;
}

.mobile_button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.mobile_button-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 60px;
  text-align: center;
}

@media screen and (min-width: 375px) {
  .mobile_button-container button {
    padding: 10px 57px;
  }
}

@media screen and (min-width: 420px) {
  .mobile_button-container button {
    padding: 10px 60px;
  }
}

@media screen and (min-width: 480px) {
  .mobile_button-container button {
    padding: 10px 60px;
  }
}

@media screen and (max-width: 319px) {
  .mobile__logo_319 {
    display: none;
  }

  .mobile__logo_320 {
    display: grid;
    margin: 0 auto;
  }
}

@media screen and (max-width: 376px) {
  .mobile__logo_321 {
    display: none;
  }
}

.mobile_search-results {
  position: absolute;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  height: max-content;
  overflow-y: auto;
  width: 100%;
  top: 4.05em;
  max-height: 400px;
}

.mobile_search-result-item {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  z-index: 1050;
}

.mobile_search-result-item img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.mobile_search-result-item p {
  margin: 0;
  flex: 1;
}
