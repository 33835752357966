* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.subtotal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 27vw;
  height: 200px;
  padding: 20px;
  background-color: #f3f3f3;
  border: 1px solid #dddddd;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  margin-left: -13px;
}

.subtotal_col {
  margin-top: -1.5em;
}

.subtotal_row {
  font-size: 14px;
}

.subtotal_row_col {
  margin-top: -2.2em;
}

.subtotal > button {
  background-color: #01d168;
  border: 1px solid;
  border-color: linear-gradient(to right, #01a853, #01d168, #00b05b) 1;
  color: white;
  margin: -22px;
  padding: 12px 40px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.subtotal > button:hover {
  background-color: #028644;
  cursor: pointer;
}

@media (max-width: 575px) {
  .subtotal {
    margin-bottom: 5em;
    height: 190px;
    padding: 20px;
    background-color: #f3f3f3;
    border: 1px solid #dddddd;
    width: 100vw;
  }

  .subtotal > button {
    margin: -22px;
    padding: 16px 40px;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .subtotal {
    margin-bottom: 5em;
    height: 190px;
    padding: 20px;
    background-color: #f3f3f3;
    border: 1px solid #dddddd;
    width: 100%;
    margin-left: 0;
  }

  .subtotal > button {
    margin: -22px;
    padding: 16px 40px;
    font-size: 16px;
    transition: background-color 0.3s ease;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .subtotal {
    margin-bottom: 5em;
    height: 200px;
    padding: 20px;
    background-color: #f3f3f3;
    border: 1px solid #dddddd;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    width: 18em;
    margin-left: -2.89em;
    line-height: 12px;
  }

  .subtotal > button {
    margin: -22px;
    padding: 16px 40px;
    font-size: 16px;
    transition: background-color 0.3s ease;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
}

@media (min-width: 768px) {
  .subtotal {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .subtotal > button {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
}