.back-to-top {
  position: fixed;
  bottom: 40px;
  right: 35px;
  z-index: 1000;
}

.back-to-top-button {
  background-color: #028644;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-to-top-button:hover {
  background-color: #01d26a;
}

@media (max-width: 767px) {
  .back-to-top {
    bottom: 80px;
  }
}
