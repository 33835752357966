.sorting {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.sorting p {
  margin: 0 10px 0 0;
}

.sorting select {
  width: 101px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}
