.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 16px;
    transition: 0.4s;
}

.shop_max {
    left: 0;
    max-width: 767px;
}
.shop_max h5 {
    font-size: large;
}

.shop_max.active,
.accordion:hover {
    background-color: #ccc;
}

.accordion:after {
    content: '\002B';
    color: #777;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.shop_max.active:after {
    content: "\2212";
}

.panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.shop_container {
    padding-top: 1.5em;
    padding-bottom: 4.7em;
    align-items: center;
    /* Adjust this value as needed */
}