.mc-header {
  background-color: #01d26a;
  text-align: center;
  padding-top: 1em;
  justify-content: center;
  font-size: small;
}

.mc-header h5 {
  color: white;
}

.shopping_cart {
  background-color: #fff;
  padding: 4vh 5vh;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
}

.cart_title {
  margin-bottom: 2vh;
}

.cart_card {
  margin: 4vh auto;
  width: 90%;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0 20px;
  border: transparent;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.cart_title b {
  font-size: 1.5rem;
}

.your_cart {
  margin-bottom: -1rem;
}

.back-to-shop {
  margin-top: 2rem;
  cursor: pointer;
}

.summary {
  border-top-right-radius: unset;
  border-bottom-right-radius: 1rem;
  color: rgb(65, 65, 65);
  padding-bottom: 1rem;
}

@media (max-width: 767px) {
  .summary {
    border-top-right-radius: unset;
    border-bottom-left-radius: 1rem;
  }
}

@media (max-width: 767px) {
  .dc__cart {
    overflow-x: hidden;
    width: 99%;
  }
}

@media (max-width: 767px) {
  .shopping_cart {
    padding: 4vh;
    border-bottom-left-radius: unset;
    border-top-right-radius: 1rem;
    overflow-x: hidden;
  }
}
