.header {
  background-color: #131921;
}

.shop-nav span {
  cursor: pointer;
}

.header_logo {
  width: 150px;
  margin: 0 2em;
  margin-top: 14px;
  height: auto;
  max-width: 100%;
  max-height: 60px;
}

.header__search {
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 20px;
  position: relative;
}

.header__dropdown {
  position: absolute;
  padding: 0.45em;
  top: 59%;
  left: 20px;
  width: 55px;
  transform: translateY(-50%);
  background: #028644;
}

.header__dropdownSelect {
  height: 26px;
  padding: 0 3px;
  border: none;
  outline: none;
  background-color: #028644;
  font-size: 16px;
  cursor: pointer;
  color: white;
  width: 48px;
  margin-left: -4px;
}

.header__searchInput {
  height: 40px;
  padding: 0 50px 0 70px;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 0 5px 5px 0;
  margin-left: 5px;
}

.header_searchIcon {
  padding: 15px;
  height: 22px !important;
}

.header__searchIcon {
  position: absolute;
  padding: 2px;
  padding-top: 8.5px;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  cursor: pointer;
}

.header__section {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3.5px;
  margin-right: 20px;
}

.header_option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
}

.header_option .signup_button {
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #028644;
  border: none;
  border-radius: 4px;
  padding: 7px 15px;
  cursor: pointer;
}

.header_option .signup_button:hover {
  background-color: #026a34;
}

.header_option .header_optionLineOne {
  font-size: 11px;
}

.header_cart {
  display: flex;
  color: white;
  align-items: center;
  margin-left: 7px;
  margin-top: 2px;
  margin-right: 2em;
}

.header_cart:hover {
  color: white;
}

.header_cartCount {
  font-weight: 600;
  background-color: #131921;
  color: #01d26a;
  width: 30px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  border-radius: 40%;
}

.header_cart span {
  margin-left: -1.8em;
  margin-top: -2em;
}

.cartTwo {
  font-size: 15px;
  font-weight: 800;
}

.header_optionLineTwo {
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
}

.header_optionLineTwo:hover {
  color: #01d26a;
}

.header_optionLineOne {
  cursor: text;
}

.header_option:hover {
  color: white;
}

.search-results {
  position: absolute;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 500px;
  overflow-y: auto;
  width: 78%;
  z-index: 10;
  margin-left: 3.7em;
}

.search-result-item {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
}

.search-result-item img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.search-result-item p {
  margin: 0;
}

/* Navbar.css */
.contact-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's on top of other elements */
}

.contactus-popup {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  overflow-y: hidden;
}

.contactus-popup button.close-btn {
  position: absolute;
  top: 5px;
  right: 0.7em;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.contactus-popup button.close-btn:hover {
  color: orangered;
}

.contactus-popup-button {
  margin-bottom: -7px !important;
}

@media (min-width: 768px) and (max-width: 875px) {
  .signup_button {
    display: ;
  }

  .header_option {
  margin-left: -2px;
  margin-right: 0px;
  color: white;
}
}