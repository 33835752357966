.cart__main {
  margin: 0;
  padding-bottom: 12px;
  width: 100%;
}

.cart_product_col-2,
.cart_product_col {
  padding: 0 1vh;
  flex: 1;
}

.cartProduct_title {
  width: 100%;
  font-size: 15px;
  white-space: normal; /* Allow the text to wrap */
  overflow: hidden; /* Hide the overflowing text */
  word-wrap: break-word; /* Ensure the text wraps at appropriate points */
}

.cart_product_col > strong {
  font-size: 16.5px;
}

.close {
  margin-right: 7px;
  font-size: 0.7rem;
  cursor: pointer;
}

.cart_product_img {
  width: auto;
}

.cart_product_quantity {
  display: flex;
  align-items: center; /* Vertically center the items */
}

.cart_product_quantity small {
  margin-right: 8px; /* Adjust space between the text and the selector if needed */
}

.cart_quantity-selector {
  display: flex;
  align-items: center; /* Vertically center the buttons and the quantity */
}

.cart_quantity-selector button {
  background-color: #01d26a;
  border: 1px solid #ccc;
  width: 24px; /* Set the desired width */
  height: 24px; /* Set the height equal to the width to make it a square */
  padding: 0; /* Remove padding */
  display: flex; /* Use flexbox to center the text */
  align-items: center; /* Vertically center the text */
  justify-content: center; /* Horizontally center the text */
  cursor: pointer;
  color: #ffffff;
  font-size: 14px; /* Adjust font size if needed */
  line-height: 1; /* Adjust line height */
  margin: 0 4px; /* Add margin between buttons and the quantity text */
}

.cart_quantity-selector span {
  margin: 0 8px; /* Adjust space between buttons and the quantity text if needed */
}

.checkout-quantity-banner {
  font-weight: 600;
  background-color: #028644;
  color: white;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 13px;
  border-radius: 50%;
  float: right;
  margin-top: -3.2em;
  margin-right: -0.5em;
  position: relative; /* or absolute or fixed, based on your layout needs */
  z-index: 2;
}

@media (max-width: 767px) {
  .cart_product_img {
    min-width: 80px;
    margin-left: -0.2em;
  }

  .cart_product_col {
    padding: 0.5rem 1vh;
  }

  .cartProduct_title {
    font-size: 12px;
    margin-left: 1.6em;
  }

  .cart_product_col > strong {
    margin-left: 1.15em;
    font-size: medium;
  }

  .col-col_check > strong {
    margin-left: 2em;
    font-size: medium;
  }

  .cart_product_quantity {
    margin-left: 1.15em;
  }

  .checkout-quantity-banner {
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    border-radius: 50%;
    margin-top: -5.2em;
    margin-right: -3em;
    position: relative; /* or absolute or fixed, based on your layout needs */
    z-index: 2;
  }

  .cart-Product_title {
    margin-left: 2em;
  }
}

@media (min-width: 1200px) {
  .cartProduct_title {
    white-space: nowrap; /* Allow the text to wrap */
    overflow: hidden; /* Hide the overflowing text */
    word-wrap: break-word; /* Ensure the text wraps at appropriate points */
  }
}
