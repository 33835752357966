.recommended-products {
  margin-top: 1em;
  padding: 20px;
  flex-grow: 1;
}

.recommended-products h4 {
  margin-bottom: 1em;
}

.recommended-products-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.recommended-product-item {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  text-align: center;
  padding: 10px;
  transition: transform 0.2s;
}

.recommended-product-item img {
  max-width: 100%;
  height: auto;
  max-height: 190px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
}


.recommended-product-item h3 {
  font-size: 1em;
  margin: 10px 0;
}

.recommended-product-item p {
  font-size: 0.9em;
  color: #333;
}

.recommended-product-item:hover {
  transform: scale(1.02);
}

@media (max-width: 767px) {
  .recommended-products-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .recommended-products-list {
    grid-template-columns: repeat(4, 1fr);
  }
}

