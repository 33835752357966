.filter {
  padding: 20px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  border-radius: 5px;
  width: max-content;
}

.reset__filter {
  font-size: 15px;
  text-decoration: underline;
}

.filter-group {
  margin-bottom: 15px;
}

.checkmark {
  padding-left: 5px;
}

.price-slider {
  display: flex;
  align-items: center;
  gap: 10px;
}

.filter h3 {
  margin-bottom: 15px;
}

.filter-group label {
  display: flex;
  margin-bottom: 5px;
}

.labeltitle {
  font-weight: bold;
}

.filter-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.filter-group select {
  cursor: pointer;
}

.filter-group input[type="number"] {
  width: calc(50% - 5px);
  display: inline-block;
  margin-right: 5px;
}

.filter-group input[type="number"]:last-child {
  margin-right: 0;
}

.filter button {
  padding: 10px 20px;
  border: none;
  background-color: #28a745;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.filter button:hover {
  background-color: #218838;
}