.chat-window {
  position: fixed;
  bottom: 155px;
  right: 5px;
  transform: translateX(-25%);
  background-color: white;
  border: 2px solid #01d26a;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 996;
  width: 330px;
  height: 435px;
  display: none;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s  ease-in-out;
  -moz-transition: all 0.5s  ease-in-out;
  overflow-x: hidden;
}

.chat-window.visible {
  display: flex;
}

.chat-body {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.ce-my-message-body {
  padding: 10px !important;
  font-size: 14px !important;
  height: max-content !important;
  background-color: #028644 !important;
}

.ce-avatar {
  display: none;
}

.ce-my-message-bubble {
  background-color: #028644 !important;
}

.ce-chat-title{
  height: 7%;
}

.ce-chat-title-text {
  color: #028644 !important;
}

.ce-chat-subtitle-text {
  color: #028644 !important;
  font-weight: 600;
}

#ce-send-message-button {
  position: relative !important;
  bottom: 2px !important;
  left: 1px !important;
  background-color: #028644 !important;
}

.ce-my-message-timestamp {
  color: #028644 !important;
}

.ce-chat-feed {
  margin-left: 0.7em;
  margin-right: 20em;
  padding: auto;
  outline: none;
  border: none;
  height: 420px !important;
  width: 320px !important;
}

.ce-chat-settings-column {
  display: none;
}

.ce-chat-list-column {
  display: none;
}

.ce-message-list {
  margin-left: -1em;
  font-size: small;
  width: 100%;
  height: 325px !important;
}

.ce-chat-header {
  margin-left: -0.7em !important;
  padding: 5px 0px !important;
  font-size: small;
  width: 100%;
}

#ce-message-form {
  margin-left: -0.4em !important;
  width: 100%;
}

.ce-their-message-body {
  margin-left: 12px !important;
  font-size: 14px !important;
}

.ce-their-message-sender-username {
  margin-left: -3.5em !important;
  font-size: 11px !important;
}

.ce-their-message-body {
  margin-left: 12px !important;
  font-size: 14px !important;
}

#ce-chat-feed-title- {
  font-size: 18px !important;
  color: #028644 !important;
}

.ce-chat-header-subtitle {
  color: #028644 !important;
  display: none;
}

#msg-textarea {
  width: 63% !important;
  max-height: 40px !important;
  overflow-y: auto !important;
  resize: none !important;
}

.ce-their-reads-row {
  margin-left: 19px !important;
  margin-top: 3px !important;
}

.ce-message-list-content {
  margin-top: 3px !important;
}

/* Ensure placeholder text is visible and styled correctly */
.ce-message-form-input::placeholder {
  position: relative;
}

/* Style for textarea to match the intended design */
.ce-message-form-input {
  outline: #01d26a !important;
  overflow: hidden;
  position: relative;
}

/* Optional: Ensure placeholder styling on focus and hover */
.ce-message-form-input:focus::placeholder {
  color: #aaa !important; /* Adjust if needed */
}
