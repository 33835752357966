.order-details {
  padding: 20px;
  overflow-x: hidden;
  margin-bottom: 2em;
  max-width: 1030px;
}

.order-header h4 {
  margin-bottom: 0;
}

.product-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.order-items img {
  width: 75px;
  height: 75px;
  object-fit: cover;
  margin-right: 15px;
}

.order-items h5 {
  font-size: 18px;
  margin-bottom: 5px;
}

.order-items p {
  margin-bottom: 5px;
}

.order-summary {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 20px;
}

.order-summary h5 {
  margin-bottom: 15px;
}

.order-summary p {
  margin-bottom: 5px;
}