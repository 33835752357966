.empty_mt-100 {
    margin-top: 5.5em;
}

.empty_card {
    margin-bottom: 30px;
    border: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    letter-spacing: .5px;
    border-radius: 8px;
    -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
}

.empty_card {
    background-color: transparent;
    border-bottom: none;
    padding: 24px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.empty_card .empty_card-body {
    padding: 30px;
}

.empty_card_body > img {
    width: 100px;
}

@media (max-width: 767px) {
    .empty_mt-100 {
        overflow-x: hidden;
    }

    .empty_card {
        overflow-x: hidden;
    }

}