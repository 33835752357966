.account-page {
    margin-bottom: 6em;
}

.account-header {
  color: black;
  text-align: center;
  padding-top: 1.3em;
  padding-bottom: 0.8em;
  margin-bottom: 1em;
}

.account-header h2 {
  font-size: 18px;
  margin-top: 10px;
  margin-left: 40%;
  font-weight: bold;
  color: black;
}

.account_cart {
  display: flex;
  align-items: center;
  margin-top: -1px;
  margin-right: -3px;
}

.account_cartCount {
  font-weight: 600;
  background-color: white;
  color: #01d26a;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  border-radius: 40%;
  margin-top: -1.6em;
  margin-left: -1.3em;
}

.acc-header {
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2.2rem;
  margin-bottom: 0.8em;
}

.account-options {
    list-style-type: none;
    padding: 0;
}

.account-options li {
    display: flex;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    font-size: 18px;
}

.account-options li i {
    margin-right: 10px;
}

.account-options li:hover {
    color: gray;
}

.signout-account {
    color: orangered;
}