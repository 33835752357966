.dc__cart {
    padding: 20px;
    background-color: #fff;
    height: max-content;
}

.dc__title {
    margin-right: 10px;
    padding: 10px;
    border-bottom: 1px solid lightgray;
}

.dc-cart_left {
    float: left;
}

.dc-cart_right {
    float: right;
}

.back-to-shop {
    margin-top: 2rem;
    cursor: pointer;
}

.cvard {
    margin-right: em;
}