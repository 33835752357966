.details_container {
  margin: 0 auto;
  width: 98%;
  display: flex;
  flex-direction: row;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-top: 1rem;
  margin-bottom: 3em;
}

.icc {
  margin-left: 1.7em;
}

.details_image {
  border-radius: 20px 0 0 20px;
  margin-top: -2em;
}

.details_pictures {
  max-width: 100%;
  height: auto;
  max-height: 400px;
  justify-content: center;
  display: flex;
  margin: 0 auto;
}

.product__details {
  width: 100%;
}

header > h2 {
  text-transform: uppercase;
}

header > p {
  font-size: smaller;
}

.details_add-to-cart {
  border-radius: 2px;
  width: 100%;
  height: 40px;
  border: 1px solid;
  margin-top: 25px;
}

.details_add-to-cart:hover {
  background-color: #28a745;
  color: #fff;
}

.details_share {
  font-size: smaller;
  cursor: pointer;
}

.details_share-dialog {
  cursor: pointer;
}

.details_verified {
  margin-top: 10px;
  font-size: 14px;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  text-transform: uppercase;
}

.details_price {
  font-weight: 400;
  font-size: 20px;
}

.details_footer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.details_cart_product_quantity {
  font-size: smaller;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.home_quantity-selector {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.home_quantity-selector button {
  background-color: #01d26a;
  border: 1px solid #ccc;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ffffff;
  font-size: 14px;
  line-height: 1;
  margin: 0 4px;
}

.cart_quantity-selector span {
  margin: 0 8px;
}

.product-details {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.product-image img {
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
}

.product-info {
  margin-top: 20px;
}

.product-info h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.product-info p {
  font-size: 18px;
  color: #555;
}

.product-price {
  font-size: 22px;
  font-weight: bold;
  margin-top: 10px;
}

.product-actions {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.view-cart_button {
  background-color: #01d26a;
  color: #fff;
  width: 100%;
  border-radius: 0;
  margin-top: 25px;
}

.view-cart_button:hover {
  background-color: #28a745;
  color: white;
}

.quantity-selector {
  display: flex;
  align-items: center;
}

.home_quantity-selector button {
  background-color: #01d26a;
  color: #fff;
  border: none;
  width: 38.5px;
  height: 38.5px;
  font-size: 18px;
  cursor: pointer;
  margin: 0 5px;
}

.home_quantity-selector button:hover {
  background-color: #28a745;
  color: white;
}

.home_quantity-selector span {
  font-size: 18px;
}

.share-buttons {
  display: flex;
  margin-top: 20px;
}

.share-button {
  background-color: #f5f5f5;
  border: none;
  padding: 10px;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.share-button:hover {
  background-color: #e0e0e0;
}

.share-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.share-dialog {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.social-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
}

.social-buttons svg {
  cursor: pointer;
  transition: transform 0.2s;
}

.social-buttons svg:hover {
  transform: scale(1.1);
}

.product_details_back-to-shop {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

.product_details_back-to-shop:hover {
  color: #028644;
}

.product_details_back-to-shop span {
  text-decoration: none;
  color: #555;
  font-size: 16px;
}

.product_details_back-to-shop span:hover {
  color: #028644;
}

.cat-name {
  padding-bottom: 0.3em;
  font-size: 14px;
  cursor: pointer;
  color: #28a745;
}

.cat-name:hover {
  color: gray;
}

@media (min-width: 1000px) {
  .details-image,
  .product-details {
    width: 100%;
    padding-left: 0;
  }

  .details-pictures {
    width: 100%;
  }

  .product_details_back-to-shop {
    margin-left: 7em;
  }
}

.product_details_back-to-shop-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: -0.8em;
  cursor: pointer;
}

.product_details_back-to-shop-mobile a {
  text-decoration: none;
  color: #555;
  font-size: 14px;
}

.product_details_back-to-shop-mobile a:hover {
  color: #333;
}

.read-more {
  cursor: pointer;
  color: #28a745;
}

.read-more:hover {
  color: #4b505e;
}
