.chat-icon-container {
  position: relative;
}

.chatpopnot {
  background-color: #028644;
  color: #fff;
  padding: 10px 15px;
  border-radius: 50px;
  position: fixed;
  bottom: 110px;
  right: 105px;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

.chat-icon {
  background-color: #01d26a;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 100px;
  right: 35px;
  z-index: 1000;
  transition: all 0.33s ease;
  -webkit-transition: all 0.33s ease;
  -moz-transition: all 0.33s ease;
}

.chat-icon.active {
  background-color: #028644;
}

.chat-icon img {
  width: 40px;
  height: 40px;
  color: white;
  filter: invert(100%);
}

.chat-icon:hover {
  background-color: #028644;
}

.message-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: 3px;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
