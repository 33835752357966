.product__style {
  transition: 0.3s ease;
  animation: fadeIn 1s;
  max-width: 210px;
}

.home__card {
  border-color: #343a40;
  padding-bottom: 7px;
  padding-left: 5px;
  padding-right: 5px;
}

.home__card:hover {
  transform: scale(1.05);
}

.add-to-cart {
  width: 100%;
  height: 40px;
  border: 1px solid #343a40;
  background-color: #ffffff;
  color: #343a40;
  cursor: pointer;
  transition: 0.2s;
  margin-top: 1rem;
  text-align: center;
  line-height: 40px;
  border-radius: 0.25rem;
}

.add-to-cart:hover {
  background-color: #026a34;
  color: white;
}

.home__card img {
  max-width: 100%;
  width: 100%;
  height: 150px;
  min-height: 80px;
  transition: 0.2s;
  min-width: 80px;
  object-fit: contain;
}

@media (min-width: 768px) and (max-width: 991px) {
  .home__card img {
      height: 90px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home__card img {
      height: 100px;
  }
}


.card-footer > strong {
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
}

.card___header p {
  font-size: 0.98em;
  word-wrap: break-word;
  white-space: nowrap; /* Prevent the text from wrapping */
  overflow: hidden; /* Hide the overflowing text */
  text-overflow: ellipsis; /* Add the ellipsis (...) */
  text-align: center !important;
  justify-content: center !important;
  align-items: center !important;
  padding-bottom: 22px;
}

.home_price > strong {
  font-family: "Tulpen One", sans-serif;
  font-size: 1.08rem;
  font-style: normal;
}

.home_price {
  text-align: center;
  justify-content: center;
  padding-top: 15px;
}

@media (max-width: 767px) {
  .home__card p {
    font-size: 0.9em;
    width: 130px;
  }

  .home_price > strong {
    font-size: 0.96rem;
  }

  .product__style {
    max-width: 135px;
  }

  .home__card img {
    max-height: 90px;
  }

  .home__card:hover {
    transform: none;
  }
}

.add-to-cart:disabled {
  background-color: #ddd; /* Example color for the disabled state */
  cursor: not-allowed;
}

.home_quantity-selector span {
  margin: 0 17px;
  font-size: 16px;
}
