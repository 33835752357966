.reset-password-container {
  font-family: Poppin, sans-serif;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.reset-password-header {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 8px;
}

.reset_logo {
  height: 30px;
  margin-top: 10%;
}

.reset-password-main {
  text-align: center;
}

.reset_back-link {
  display: inline-block;
  margin-bottom: 15px;
  color: #000;
  text-decoration: none;
}

.reset_back-link:hover {
  color: #028644;
}

.reset-password-main h2 {
  font-size: 29px;
  margin-bottom: 25px;
  padding-top: 4em;
}

.reset-password-main p {
  margin-bottom: 20px;
  color: #555;
}

.reset-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reset-password-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.reset-password-form button {
  width: 100%;
  padding: 12px;
  background-color: #01D26a;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 17px;
  cursor: pointer;
}

.reset-password-form button:hover {
  background-color: #028644;
}

.error {
    color: red;
    font-size: 15px;
}

.message {
  color: #028644;
  font-size: 15px;
}

.reset_container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 20px;
    margin: 0 auto;
    margin-bottom: 10px;
}