.ve {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
}

.ve__logo {
    margin-top: 40px;
    margin-bottom: 35px;
    object-fit: contain;
    width: 150px;
    margin-right: auto;
    margin-left: auto;
}

.ve_container {
    width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 20px;
    margin: 0 auto;
}

.ve_container>h2 {
    font-weight: 500;
    font-size: medium;
    margin-bottom: 25px;
}

.ve_container>form>h5 {
    font-size: 17px;
}

.ve_container>p {
    font-size: 13px;
    margin-top: -5px;
}

.ve_signInButton {
    background: #01D26a;
    border-radius: 2px;
    width: 100%;
    height: 35px;
    border: 1px solid;
    border-color: linear-gradient(to right, #01A853, #01D168, #00B05B) 1;
    color: white;
}

.ve_signInButton:hover {
    background-color: #028644;
    color: white;
    cursor: pointer;
}



@media (min-width: 768px) {
    .ve {
        margin-top: 6%;
    }
}

.lastlast {
    margin-bottom: -3px;
}