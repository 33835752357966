.checkout_payment {
  overflow-x: hidden;
}

.checkout_payment > h5 {
  text-align: center;
  padding: 10px;
  font-weight: 400;
}

.check_logo {
  width: 55px;
  height: auto;
}

.checkout_question {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.payment__summary {
  border-top-right-radius: unset;
  border-bottom-right-radius: 1rem;
  color: rgb(65, 65, 65);
  padding-bottom: 1rem;
}

.check_cart {
  background-color: #fff;
  padding-left: 10em;
}


@media (max-width: 767px) {
  .payment__summary {
    border-top-right-radius: unset;
    border-bottom-left-radius: 1rem;
  }

  .check_cart {
    background-color: #fff;
    padding-left: 2em;
    padding-right: 2em;
    height: max-content;
    margin-bottom: -1em;
  }
}

.checkout__items {
  width: 100%;
}

.ptag_checkout {
  text-decoration: underline;
  padding-left: 1em;
  padding-top: 0.7em;
  font-size: 14px;
  cursor: pointer;
  color: #028644;
}

.checkform {
  display: flex;
  flex-direction: column;
}

.checkform input {
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.checkform input[disabled] {
  background-color: #f5f5f5;
  color: #888;
}

.chch {
  margin-top: -4em;
  margin-bottom: 4em;
  font-weight: 500;
}

.form-side {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.form-side input {
  flex: 1;}

.select {
  flex: 1;
  height: 45px;
  margin-top: 5px;
  border-radius: 5px;
}