.invoice {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  overflow-y: hidden;
}

.invoice-container button.close-btn {
  position: absolute;
  top: 0px;
  right: 0.7em;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.invoice-container button.close-btn:hover {
  color: orangered;
}

.invoice-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.invoice-container {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 75vh;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.invoice-header {
  margin-bottom: 20px;
}

.invoice-header h2,
.invoice-header p,
.invoice-header h1 {
  margin: 0;
}

.invoice-body {
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  border: 1px solid #eee;
  text-align: left;
}

th {
  background: #f5f5f5;
}

.invoice-footer {
  text-align: center;
}

.invoice-footer button {
  margin-bottom: 10px;
  cursor: none;
}

@media (max-width: 767px) {
  .invoice-container {
    position: fixed;
    left: 26%;
    border-radius: 0;
    transform: translateX(-26%);
    background-color: white;
    padding: 10px 9px;
    width: 100%;
    min-width: 100vsw;
    max-height: 90vh;
    overflow-x: hidden;
  }

  .mainIn .invoice-header .header1 {
    margin-top: -1em;
    margin-bottom: 1em;
  }

  .close-btn {
    margin-top: 0.5em;
  }
}

.head1 {
  font-size: 1em;
  font-weight: 600;
  color: #000;
}

.head2 {
  font-size: 1.6em;
  font-weight: bold;
  color: #222;
}

.headp {
  font-size: 0.9em;
}

.mainIn {
  margin-top: 2.2em;
}

.header2 {
  float: right;
}

.header1 {
  margin-top: 2px;
  display: flex;
}

.header2 {
  margin-top: -5px;
}

.header1 img {
  width: 41px;
  height: auto;
}

.buttt {
  cursor: none !important;
}
