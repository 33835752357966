.filter-sort-bar {
  margin-bottom: 20px;
  margin-top: 6.5em;
  display: block;
}
.sidebar .socials .download-catalog a {
  align-items: center;
  padding: 3px;
  margin: 5px 0;
  height: 45px;
  text-decoration: none;
  color: #000;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebar .socials .download-catalog a span {
  display: flex;
  align-items: center;
}

.sidebar .socials .download-catalog a p {
  margin-left: 10px;
}

.sidebar .socials .download-catalog a.instagram:hover {
  background-color: #E4405F; /* Instagram brand color */
  color: white; /* Change text color on hover */
}

.sidebar .socials .download-catalog a.facebook:hover {
  background-color: #3b5998; /* Facebook brand color */
  color: white; /* Change text color on hover */
}

.sidebar .socials .download-catalog a.linkedin:hover {
  background-color: #0077b5; /* LinkedIn brand color */
  color: white; /* Change text color on hover */
}

.sidebar .socials .download-catalog a.youtube:hover {
  background-color: #FF0000; /* YouTube brand color */
  color: white; /* Change text color on hover */
}

.socials {
  margin-top: 2em;
}

@media (max-width: 767px) {
  .sidebar {
    display: none;
  }
}